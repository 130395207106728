<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-header">
        <div class="d-flex flex-row justify-content-between align-items-start w-100">
          <div class="card-title">การแข่งขันที่กำลังจะมาถึง</div>
          <div class="d-flex flex-column justify-content-center align-items-end">
            <router-link to="/events/soccer/next-match/create">
              <b-button variant="gradient-primary" block>
                สร้างการแข่งขัน
              </b-button></router-link>
          </div>
        </div>
      </div>
      <b-table striped hover small responsive show-empty class="mt-2 position-relative items-center" :per-page="perPage"
        :items="items.data" :fields="fields" fixed>

        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
    'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(image_link)="{ item }">
          <img :src="item.image_link" alt="Image" width="150">
        </template>
        <template #cell(home)="{ item }">
          <div class="text-center flex flex-column">
            <img :src="item.home.crest" class="mb-1" alt="Image" width="80">
            <div>

              {{ item.home.shortName }}
            </div>
          </div>
        </template>
        <template #cell(away)="{ item }">
          <div class="text-center flex flex-column">
            <img :src="item.away.crest" class="mb-1" alt="Image" width="80">
            <div>
              {{ item.away.shortName }}

            </div>
          </div>
        </template>
        <template #cell(start_date)="{ item }">
          {{ item.start_date | dateFormat }}
        </template>
        <template #cell(status)="{ item }">
          <b-form-group label="สถานะ" label-for="image_link">
            <b-form-checkbox v-model="item.status" name="check-button" switch @change="changeStatus(item)"></b-form-checkbox>
          </b-form-group>
        </template>
        <template #cell(actions)="{ item }">
          <b-dropdown id="dropdown-3" text="Success" variant="success">
            <template #button-content>
              <i class="far fa-edit mr-50" /> จัดการ
            </template>
            <b-dropdown-item @click="$router.push(`/events/soccer/next-match/${item.id}/edit`)"><i
                class="fas fa-edit mr-1 text-info" /> แก้ไข
            </b-dropdown-item>
            <b-dropdown-item @click="destroy(item.id)"><i class="fas fa-trash mr-1 text-danger" /> ลบ
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="card-body d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="getData()" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="page" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0" @input="getItems()">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BFormCheckbox, BFormSelect, BFormGroup, BPagination, BDropdownItem, BDropdown, BButton, BTable, BOverlay, BIconController, BFormInput } from 'bootstrap-vue';
import Create from './create.vue';
import Edit from './edit.vue';
import moment from 'moment-timezone';
import * as _ from 'lodash';

export default {
  components: {
    BFormCheckbox, BFormSelect, BFormGroup, BPagination, BDropdownItem, BDropdown, BButton, BTable, BOverlay, BIconController, BFormInput,
    Create, Edit
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format("YYYY-MM-DD HH:mm")
    }
  },
  data: () => ({
    isLoading: false,
    perPage: 10,
    pageOptions: [10, 15, 20],
    totalRows: 1,
    page: 1,
    fields: [
      { key: 'image_link', label: 'รูปภาพ' },
      { key: 'home', label: 'Home' },
      { key: 'away', label: 'Away' },
      { key: 'status', label: 'สถานะ' },
      { key: 'start_date', label: 'วันเวลา' },
      { key: 'actions', label: '#' },
    ],
    items: {
      data: []
    },
    debouncedSearch: ''
  }),
  computed: {
    search: {
      get() {
        return this.debouncedSearch
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debouncedSearch = val
        }, 400)
      }
    }
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.isLoading = true;

      try {
        const { data } = await this.$http.get('/events/soccerNextMatches');

        let mapData = data;
        mapData.data = data.data.map(item => {
          item.status = item.status === 1 ? true : false;
          return item;
        })
        this.items = mapData;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async changeStatus(item) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.post('/events/soccerNextMatchChangeStatus', item);

        this.getItems();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async destroy(id) {
      this.isLoading = true;

      try {
        const { data } = await this.$http.delete(`/events/soccerNextMatchDelete/${id}`);

        this.getItems();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>